@import "../../variables.scss";

.color-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .color-border {
        display: inline-flex;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 40px;
        padding: 3px;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        &.selected {
            border-color: gray;
        }

        .color {
            height: 40px;
            width: 40px;
            border: 1px solid gray;
            border-radius: 40px;

            &.gray {
                background-color: lightgray;
            }

            &.white {
                background-color: white;
            }

            &.black {
                background-color: black;
            }

            &.yellow {
                background-color: $yellow;
            }
        }
    }
}
