@import "../../variables.scss";

.breadcrumbs {
    padding: 1.2rem 0;
    a {
        color: black;

        &:hover {
            color: $yellow;
        }

        &::after {
            content: ">";
            margin: 0 0.6rem;
        }

        &:hover::after {
            color: black;
        }

        &:last-child::after {
            display: none;
        }
    }
}
