@import "../../variables.scss";

#about {
    &.mission {
        .header {
            background-image: url("../../assets/about/mission.jpg");
            background-position-y: 55%;
        }

        #again {
            padding: 1.5rem 0;
        }
    }
    
    .header {
        height: 40vh;
        background-image: url("../../assets/product/p3.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .navbar {
            display: flex;
            flex-grow: 1;
            width: 100%;
            background-color: $yellow;
            z-index: 29;

            .navbar-brand {
                font-weight: bold;
                display: flex;
                align-items: center;
                padding: 0.5rem 0.75rem;
            }

            .navbar-start {
                .navbar-item {
                    padding: 0.5rem 2rem;
                }
            }
        }
    }

    #reports {
        .container {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
        }

        .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 3rem 0;

            h2 {
                font-weight: 500;
                font-size: 1.5rem;
            }
            
            h2 + .button {
                margin-top: 1.8rem;
            }
        }
    }

    #usps {
        .container {
            div {
                display: flex;
    
                &.right {
                    justify-content: flex-end;
                }
            }
    
            .h1 {
                font-size: 2.2em;
                display: inline;
                background-color: $yellow;
                padding: 0 1.4rem;
                margin: 0.2rem 0;
                line-height: 1.5;
            }
        }
    }

    #again {
        .content {
            padding: 1rem 0;
            max-width: 428px;
            text-align: center;
        }
        
        .column {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray;
        }

        .column + .column {
            background-color: $blue;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 70%;
            }
        }
    }

    #responsible-consumption .container {
        height: 50vh;
        background-image: url("../../assets/about/swing.jpg");
        background-size: cover;
        background-repeat: no-repeat;
    }

    #materials-matter {
        p {
            margin-bottom: 1.8rem;
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            .button {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (min-width: $tablet-screen) {
    #about {
        .header {
            background-size: 100vw;
            background-position-y: 65%;
        }
        
        #reports {
            .column {
                padding: 5rem 0;
            }
        }

        #usps {
            .container {
                .h1 {
                    font-size: 5em;
                    padding: 0 3.4rem;
                    margin: 0.8rem 0;
                    line-height: 1;
                }
            }
        }

        #again {
            padding: 0;

            .content {
                padding: 2rem 0;
                text-align: left;
            }
        }

        #responsible-consumption .container {
            height: 75vh;
            background-size: 100%;
            background-position-y: 50%;
        }
    }
}

@media screen and (min-width: $desktop-screen) {
    #about {
        #usps {
            div {
                &.invert .h1 {
                    margin-top: -2rem;
                }
            }
            .h1 {
                font-size: 8em;
            }
        }

        #again {
            .content {
                padding: 8rem 0;
            }
        }
    }
}
