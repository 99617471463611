.product-promo {
    position: relative;
    
    img {
        display: block;
        width: 100%;
    }

    .pet {
        position: absolute;
        top: 1.2rem;
        right: 10%;
    }

    .caption {
        position: absolute;
        color: white;
        bottom: 1.2rem;
        width: 100%;
        padding: 0 16px;

        p {
            margin: 0.2rem 0 0.8rem;
        }

        .is-flex {
            align-items: center;
            justify-content: space-between;
        }
    }
}
