.canary-select {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;

    select {
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 15px 25px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 30px;
        background: #ffffff;
        color: black;
        appearance: none;
        font-size: 14px;
        font-weight: 300;

        &::-ms-expand {
            display: none;
        }

        &:hover,
        &:focus {
            color: #000000;
            background: #ffffff;
        }

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .select_arrow {
        position: absolute;
        top: 18px;
        right: 25px;
        width: 13px;
        height: 13px;
        border: solid #7b7b7b;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
    }

    select:hover ~ .select_arrow,
    select:focus ~ .select_arrow {
        border-color: #000000;
    }

    select:disabled ~ .select_arrow {
        border-top-color: #cccccc;
    }
}
