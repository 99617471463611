@import "../../variables.scss";

#space-preview {
    background:
        url("../../assets/home/office-rug.jpg") no-repeat  -200vw,
        url("../../assets/home/bedroom-rug.jpg") no-repeat -100vw,
        url("../../assets/home/living-room-rug.jpg") no-repeat  0,
        url("../../assets/home/kitchen-rug.jpg") no-repeat  100vw;
    background-size: cover;
    padding: 1rem 0;
    transition: all 0.6s;

    .button {
        display: flex;
        margin: 50px auto 0;
        max-width: 215px;
    }

    .selected {
        color: $yellow;
    }

    .is-50 {
        opacity: 0.5;
    }

    .is-75 {
        opacity: 0.75;
    }

    .h1 {
        max-width: 230px;
        margin: 0 auto;
        cursor: pointer;
        transition: all 0.6s;
        user-select: none;
    }
}
@media screen and (min-width: $tablet-screen) {
    #space-preview {
        padding: 3rem 0;
    }
}

@media screen and (min-width: $desktop-screen) {
    #space-preview {
        padding: 3rem 0;
        min-height: calc(28pt * 12);
        
        .h1 {
            max-width: none;
            font-size: 28pt;
        }

        .is-0 {
            transform: translateY(calc(28pt * 3));
        }
        .is-1 {
            transform: translateY(calc(28pt * 2));
        }
        .is-2 {
            transform: translateY(calc(28pt * 1));
        }
        .is-3 {
            transform: translateY(0pt);
        }
        
    }
}

@media screen and (min-width: $large-screen) {
    #space-preview {
        min-height: calc(44pt * 10);

        .is-0 {
            transform: translateY(132pt);
        }
        .is-1 {
            transform: translateY(88pt);
        }
        .is-2 {
            transform: translateY(44pt);
        }
        .is-3 {
            transform: translateY(0pt);
        }

        .h1 {
            max-width: none;
            margin: inherit;
            line-height: 44pt;
            font-size: 38pt;
        }
    }
}
