@import "variables.scss";

.is-bold {
    font-weight: bold;
}

.is-medium {
    font-weight: 500;
}

.is-light {
    font-weight: 300;
}

.has-side-padding {
    padding-left: 0rem;
    padding-right: 0rem;
}

.column,
.container {
    &.is-blue {
        background-color: $blue;
    }

    &.is-light-blue {
        background-color: $light-blue;
    }
    
    &.is-gray {
        background-color: $gray;
    }
}

.text {
    padding: 2rem 16px;
    max-width: 428px;
    margin: 0 auto;
}

// PET icon
.PET_icon_svg__cls-2{
    stroke-miterlimit: 10;
    fill: none;
    stroke: #010101;
    stroke-width: 4.09px;
}

// Washable icon
.washable_svg__cls-1 {
    fill: $yellow;
}

@media screen and (min-width: $desktop-screen) {
    .has-side-padding {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .text {
        padding: 6rem 0;
    }
}
