@import "../../variables.scss";

.footer {
    background-color: $footer;
    padding: 1.5rem;

    .links {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        max-width: 560px;
    }

    a {
        color: inherit;
    }

    h1 {
        font-size: 25pt;
        margin-bottom: 0.6rem;
        font-weight: bold;
        cursor: pointer;

        &:hover { color: $yellow;}
    }

    h4 {
        margin-bottom: 0;
        cursor: pointer;

        &:hover { color: $yellow;}
    }

    .contacts {
        display: flex;
        flex-direction: column-reverse;

        svg, h3 {
            cursor: pointer;
        }

        svg {
            font-size: 18pt;
        }

        h3 {
            color: $yellow;
            display: block;
            font-weight: bold;

            &:hover { color: $yellow;}
        }

        svg:hover path {
            fill: $yellow;
        }

        .social-media {
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
        }

        .details {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: left;
            margin-bottom: 1.2rem;
        }
    }

    .copywrite {
        display: flex;
        justify-content: center;

        div {
            display: flex;
            justify-content: space-between;
            max-width: 400px;
            min-width: 300px;
            
            span {
                font-size: 7pt;
                cursor: pointer;
    
                &:hover { color: $yellow;}
            }
        }
    }
}

@media screen and (min-width: $tablet-screen) {
    .footer {
        padding: 3rem 1.5rem 6rem;

        .links {
            flex-direction: row;
        }

        .contacts {
            flex-direction: row;
    
            .details {
                flex-direction: row;
                text-align: left;
                margin-bottom: 0;
                
                h3 {
                    margin-left: 2.6rem;
                }
            }

            .social-media {
                margin: 0;
            }
        }

        .details {
            align-items: flex-start;
        }

        .copywrite {
            justify-content: flex-start;
        }
    }
}

@media screen and (min-width: $desktop-screen) {
    .footer {
        h1 {
            margin-bottom: 1.6rem;
            font-weight: bold;
            cursor: pointer;

            &:hover { color: $yellow;}
        }

        h4 {
            margin-bottom: 2.2rem;
            cursor: pointer;

            &:hover { color: $yellow;}
        }

        .contacts {
            svg, h3 {
                font-size: 20pt;
            }
        }

        .copywrite {
            span {
                font-size: 10pt;
            }
        }
    }
}
