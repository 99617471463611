@import "../../variables.scss";

.hero {
    &.is-fullheight {
        min-height: 85vh;
        display: flex!important;

        .hero-body {
            background-image: url("../../assets/home/hero.jpg");
            background-size: cover;
            background-position-y: center;

            h1, p {
                max-width: 240px;
            }

            p {
                margin-bottom: 1.8rem;
            }
        }
    }
}

@media screen and (min-width: $desktop-screen) {
    .hero {
        &.is-fullheight {
            .hero-body {
                h1, p {
                    max-width: 590px;
                }

                p {
                    margin-bottom: 1.8rem;
                }
            }
        }
    }
}
