@import "../../variables.scss";

#home {
    #materials {
        .column + .column {
            background-image: url("../../assets/home/geckoooo.png");
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            min-height: 40vh;
        }
    }

    #we-test-everything {
        .has-text-centered {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (min-width: $desktop-screen) {
    #home {
        #materials {
            .column + .column {
                min-height: inherit;
            }
        }    
    }
}
