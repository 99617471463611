@import "../../variables.scss";

.learn-more {
    background-position: 50%;
    background-size: cover;

    div {
        height: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        color: white;
        text-align: center;
        padding: 1.2rem 16px;
        background-color: rgba(0,0,0,.3);

        p {
            margin-bottom: 0.8rem;
        }
    }
}


@media screen and (min-width: $desktop-screen) {
    .learn-more {
        div {
            height: 480px;
        }
    }
}
