@import "../../variables.scss";

#product {
    .title {
        font-size: 38pt;
        margin-bottom: 0.6rem;
    }

    .images {
        padding: 0;

        img {
            display: block;
        }

        .left {
            flex: none;
            width: 162px;

            img {
                padding: 0.8rem 0.8rem 0 0
            }
        }

        .right {
            img {
                padding: 0.8rem 0 0 0;
            }
        }

        .arrows {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 4rem;
            line-height: 1;
        }
    }

    p + h3 {
        margin-top: 0.8rem;
    }

    h3 + .canary-select,
    h3 + .color-picker,
    .color-picker + .button {
        margin-top: 0.4rem;
    }

    .color-picker {
        margin-bottom: 0.4rem;
    }

    .buy-usps {
        margin-top: 0.8rem;
        text-align: center;
        font-size: 10pt;

        svg {
            width: 1em;
            height: 0.8em;
            margin-right: 0rem;
        }

        span + span {
            margin-left: 0.6rem;
        }
    }

    .split-1-3 {
        padding: 1.5rem 0 0 0;

        .with-content {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 420px;
            padding-top: 2rem;
            padding-bottom: 2rem;

            .content {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .content {
            max-width: 318px;
            margin: 0 auto;

            p + h2,
            h2 + .button {
                margin-top: 0rem;
            }

            h1 + h3 {
                margin-top: 1.8rem;
            }
        }

        .usps {
            padding: 3rem 1.5rem;
            text-align: center;
            
            .column-icon {
                display: flex;
                justify-content: center;
            }

            .icon {
                text-align: center;
                width: 5rem;
                height: auto;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .image {
            background-position: 50% 50%;
            background-size: cover;
            min-height: 220px;

            &.bundle {
                background-image: url("../../assets/product/p1.jpg");
            }

            &.baby {
                background-image: url("../../assets/product/baby.jpg");
            }
        }
    }

    .split-1-3 + .split-1-3 {
        padding: 0;
    }

    .separator > .h1 {
        margin: 0.8rem 0;
    }

    .column.is-5.is-offset-1 {
        padding-left: 0;
        padding-right: 0;
    }

    #better-together {
        padding-top: 3rem;

        .container {
            display: flex;
            justify-content: flex-end;
            background-image: url("../../assets/product/carpets.jpg");
            background-position: 80% 50%;
            background-size: cover;
            height: 80vh;

            .content {
                text-align: right;
                max-width: 400px;
                padding: 2rem;
            }
        }
    }
}

@media screen and (min-width: $tablet-screen) {
    #product {
        .buy-usps {
            svg {
                margin-right: 0.1rem;
            }
    
            span + span {
                margin-left: 1.2rem;
            }
        }

        .split-1-3 {
            .with-content {
                min-height: 620px;

                .content {
                    display: inline-block;
                }
            }

            .content {
                p + h2,
                h2 + .button {
                    margin-top: 0.8rem;
                }
            }

            .usps {
                text-align: left;

                .icon {
                    height: 3rem;
                    width: 3rem;
                }
            }
        }

        .separator > .h1 {
            margin: 1.8rem 0;
        }

        #better-together {
            .container {
                background-position: 0% 50%;
            }
        }
    }
}
