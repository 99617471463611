@import "../../variables.scss";
@import "./slick.scss";
@import "./slick-theme.scss";

$slide-margin: 5px;
$arrow-side: 0;

#product-images-slider {
    div, img {
        &:focus {
            outline: none;
        }
    }

    img {
        vertical-align: middle;
    }

    .slick-slider {
        margin: -$slide-margin;

        .slick-slide {
            line-height: 1;
    
            & > div {
                margin: $slide-margin;
            }
    
            div > div {
                display: block!important;
            }
        }
    }

    .slick-slider + .slick-slider {
        margin: calc(-#{$slide-margin} - 2px);
        margin-top: $slide-margin;
        
        .slick-slide div {
            border: 1px solid transparent;
        }

        .slick-current div {
            border: 1px solid $yellow;
        }
    }
}

@media screen and (min-width: $desktop-screen) {
    .slick-slider + .slick-slider {
        // padding-bottom: 40px;

        .slick-prev, .slick-next {
            top: calc(#{$slide-margin} * 20);
        }

        .slick-prev {
            left: $arrow-side;
        }
        .slick-next {
            right: $arrow-side;
            left: auto;
        }
    }
}
