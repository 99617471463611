@import "../../variables.scss";

#product-details {
    padding: 0;

    .text {
        .h1, p + p {
            margin-bottom: 2.2rem;
        }
    }

    .usps {
        position: relative;

        img {
            width: 100%;
        }

        .usp {
            cursor: pointer;
            position: absolute;
            
            span {
                font-weight: bold;
            }
            
            .plus {
                background-color: $yellow;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                line-height: 1;
                margin-right: 0.8rem;
                cursor: pointer;
            }
            
            .text-box {
                display: none;
                position: absolute;
                background-color: white;
                padding: 1.2rem;
                z-index: 10;
                top: 0;
                left: 0;
                width: 220px;
            }
            
            &.open .text-box {
                display: block;
            }

            &:nth-child(2) {
                top: 12%;
                left: 10%;
            }

            &:nth-child(3) {
                top: 32%;
                left: 20%;
            }

            &:nth-child(4) {
                top: 52%;
                left: 30%;
            }

            &:nth-child(5) {
                top: 72%;
                left: 40%;
            }
        }
    }
}

@media screen and (min-width: $desktop-screen) {
    #product-details {
        .text {
            padding: 3rem;
        }

        .usps {
            .usp {
                &:nth-child(2) {
                    top: 12%;
                    right: 15%;
                    left: auto;
                }
    
                &:nth-child(3) {
                    top: 45%;
                    left: 15%;
                }
    
                &:nth-child(4) {
                    top: 55%;
                    left: 40%;
                }
    
                &:nth-child(5) {
                    top: 65%;
                    left: 60%;
                }
            }
        }
    }
}
