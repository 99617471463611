@import "../../variables.scss";

/* Slider */
/* Arrows */
$arrow-side-mobile: 5vh;
$arrow-side-desktop: 25vh;

.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    
    z-index: 10;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 80px;
    line-height: 1;

    opacity: 1;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: $arrow-side-mobile;
}
.slick-prev:before
{
    content: '←';
}

.slick-next
{
    right: $arrow-side-mobile;
    left: auto;
}
.slick-next:before
{
    content: '→';
}

/* Dots */
.slick-dots
{
    position: absolute;
    display: block;

    width: 15px;
    padding: 0;
    margin: 0;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 15px;
    height: 15px;
    margin: 5px 5px;
    padding: 0;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 50%;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 15px;
    height: 15px;
    padding: 15px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    display: none;
}
.slick-dots li.slick-active {
    background-color: $yellow;
}

@media screen and (min-width: $desktop-screen) {
    .slick-prev
    {
        left: $arrow-side-desktop;
    }
    .slick-next
    {
        right: $arrow-side-desktop;
        left: auto;
    }
}
