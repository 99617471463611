@import "../../variables.scss";

#products-slide {
    background-color: $blue;
    margin-top: 60px;
    padding: 0;
    position: relative;
    display: block;
    border-bottom: 1px solid black;

    .caption {
        position: absolute;
        left: 50%;
        transform: translateX(-75%);
        top: 35px;
        z-index: 10;
        width: 100%;

         h1, h2 {
             margin-bottom: 0;
             color: white;
             font-weight: 700;
             text-align: right;
         }
    }

    .products {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50vh;

        .product-images {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            img {
                max-height: 150px;
                margin: 0 auto;
                display: none;
            }

            .center {
                display: block;
            }
        }

        .arrows {
            position: absolute;
            z-index: 10;
            width: 75%;
            display: flex;
            justify-content: space-between;

            svg {
                width: 40px;
                height: 16px;
            }
        }
    }
}

@media screen and (min-width: $tablet-screen) {
    #products-slide {
        padding: 2rem 0;

        .caption {
            transform: translateX(-100%);
            top: 75px;
        }

        .products {
            min-height: 70vh;
            
            .product-images {
                img {
                    max-height: 250px;
                    margin: 0;
                    transform: translateX(-100px);
                    display: block;
                }

                .center {
                    transform: scale(1.8);
                }

                .center + img {
                    transform: translateX(120px)
                }
            }

            .arrows {
                svg {
                    width: 100px;
                    height: 35px;
                }
            }
        }
    }
}
