@import "../../variables.scss";

.navbar {
    // TODO: make it fixed and change with scroll
    position: absolute !important;

    &.is-transparent {
        background-color: transparent;

        a {
            color: white;

            &:hover {
                color: #ffcd00;
            }
        }

        .navbar-brand svg {
            fill: white;
        }
    }

    &.is-light {
        background-color: white!important;

        .navbar-start,
        .navbar-brand {
            & > a.navbar-item {

                &:hover, &:focus {
                    color: $yellow!important;
                    background-color: white!important;
                }

                &:focus {
                    color: black;
                }
            }
            
            .navbar-link:focus {
                background-color: white!important;
            }
        }

        .navbar-brand svg {
            fill: black;
        }
    }
    
    a {
        font-weight: 700;
        font-size: 1.5em;
        color: black;

        &.active {
            background-color: transparent;
            color: $yellow;
            font-weight: 700;
        }
    }

    .navbar-menu {
        background-color: $gray;
    }

    .navbar-start {
        flex-grow: 1;
        justify-content: center;
    }

    .has-full-dropdown {
        position: static;
    }

    .navbar-dropdown {
        background-color: white;
        padding: 0;
        display: none;
        border: 0;
        
        h1, .h1 {
            font-size: 25pt;
        }

        h2, .h2 {
            font-size: 18pt;
        }

        p {
            font-size: 9pt;
        }

        .has-padding {
            padding: 1.2rem 1.6rem;
        }
        
        .has-menu {
            h2 {
                font-weight: 500;
            }

            h2 + a {
                margin-top: 1.2rem;
            }
            
            a.h2 {
                margin-bottom: 0;
                display: block;
                color: black;
                font-weight: 400;
                cursor: pointer;
    
                &:hover {
                    color: $yellow;
                }
            }
        }

        .product-promo {
            img {
                max-height: none;
            }
            .button {
                color: black;
                font-weight: bold;

                &:hover {
                    color: black;
                }
            }
        }

        .usp {
            padding-bottom: 0;
            color: black;
            
            a {
                font-size: 9pt;
                color: $yellow;
            }

            h2 + p {
                margin-top: 0;
            }
        }
    }
}

@media screen and (min-width: $desktop-screen) {
    .navbar {
        > .container .navbar-brand .navbar-item {
            padding-left: 1.5rem;
        }

        .navbar-menu {
            background-color: inherit;
        }

        .navbar-item.is-hoverable:hover .navbar-dropdown {
            display: block !important;
        }
        .navbar-item.is-hoverable:focus-within .navbar-dropdown {
            display: none;
        }

        a {
            font-weight: 500;
            font-size: 1em;
        }
    }
}
