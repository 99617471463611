@import "../../variables.scss";

#kickstarter {
    .container {
        padding: 0 16px;

        &.is-fullhd {
            padding: inherit;
        }
    }

    .header {
        h1 span {
            font-weight: normal;
        }
        
        p {
            margin: -0.8em 0 0;
            line-height: 1;

            svg {
                width: 76px;
                height: 28px;
                position: relative;
                top: 1em;
            }
        }
    }

    h1 + h2 {
        margin-top: 1.2rem;
    }

    h2 + h2 {
        margin-top: 0.8rem;
    }

    .button {
        margin: 2.4rem auto;
        height: 70px;

        &.is-cta {
            font-size: 1.5rem;
            border-radius: 20px;
            margin: 0.6rem 0;
        }
    }

    .usps {
        div {
            display: flex;
        }

        .usp {
            display: inline-block;
            background-color: $yellow;
            padding: 0 2.2rem;
            margin: 1.2rem 0;
            line-height: 1.6;
        }
    }

    #hero {
        background-color: $gray;
        background-image: url("../../assets/kickstarter/living-room-with-rug.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        transition: 1s;
        
        position: relative;

        .container {
            height: 100%;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            
            h1 {
                svg {
                    vertical-align: middle;
                }
            }
    
            .klaviyo-form {
                max-width: 450px;
                padding: 10px 5px;
                margin-left: 0;
                
                p {
                    text-align: left!important;
                    font-size: 18pt;
                    line-height: 1.1;
                    padding: 10px 6px;

                    &.is-small {
                        font-size: 14px;
                    }
                }

                button, input {
                    border-radius: 0;
                }

                input {
                    flex-grow: 1;
                    padding: 0 0 0 16px;
                    height: 38px;
                }

                button {
                    background-color: $yellow;
                    height: 38px;
                    width: 120px;
                    text-align: center;
                }

                div {
                    overflow: hidden;
                }

                .klaviyo_header {
                    font-weight: 700;
                    font-size: 24pt;
                    line-height: 1.1;
                    padding: 10px 6px;
                }
                .klaviyo_subheader {
                    font-size: 18pt;
                    line-height: 1.1;
                    padding: 10px 6px;
                }
            }
            
            .hero-footer {
                display: flex;
                width: 100%;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;

                .icons {
                    flex-shrink: 0;
                    img {
                        height: 40px;
                        margin-right: 10px;
        
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .button {
                    height: 40px;
                    margin: 0;
                }
            }
        }
    }

    #coming-soon {
        max-width: 950px;
        margin: 2.2rem auto;

        .container {
            display: flex;
            flex-direction: column;
        }
    }

    #rug-details {
        .with-image {
            position: relative;
            
            img {
                width: 100%;
            }

            .usps {
                position: absolute;
                bottom: 50px;
                left: -16px;

                &.right {
                    left: auto;
                    right: -16px;
                }
            }
        }

        .has-content-centered {
            display: flex;
            align-items: center;
            padding: 1.2rem 16px!important;
        }
    }

    #banner {
        .notification {
            background-color: $yellow;
        }
    }

    #learn-more {
        h2 + p {
            margin-top: 3.2rem;
        }

        .container {
            display: flex;
            flex-direction: column;
        }
    }

    footer.footer#footer {
        max-width: 950px;
        background-color: white;

        .links {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            
            a:hover {
                color: $yellow;
            }
        }

        .social-media {
            display: flex;
            justify-content: center;

            a {
                background-color: $blue;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                margin-right: 20px;


                svg {
                    margin: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .address {
            p {
                font-size: 15pt;
            }
        }
    }
}

@media screen and (min-width: 377px) {
    #kickstarter {
        #hero {
            .container {
                .hero-footer {
                    justify-content: space-between;
                }
            }
        }
    }
}

@media screen and (min-width: $desktop-screen) {
    #kickstarter {
        .header p svg {
           width: 112px;
        }

        #hero {
            background-position: left bottom;
        }

        #rug-details {
            .with-image {
                .usps {
                    left: -30px;
    
                    &.right {
                        left: auto;
                        right: -30px;
                    }
                }
            }
        }

        #learn-more {
            max-width: 950px;
            margin: 2.2rem auto;
        }

        footer.footer#footer {
            margin: 2.2rem auto;
            padding: 3rem 0;
        }
    }
}
